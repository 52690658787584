import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, GLOBAL, LINE_HEIGHT, SPACE } from '../../constants';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const PStyles = (props) => css`
  font-family: ${GLOBAL.BASE_FONT_FAMILY};
  font-size: ${FONT_SIZE.SMALL};
  line-height: ${LINE_HEIGHT.BODY.SMALL};
  font-weight: ${FONT_WEIGHT.REGULAR};
  letter-spacing: normal;
  margin: 0 0 ${props.noSpacing ? 0 : SPACE.X1} 0;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    font-size: ${FONT_SIZE.MEDIUM};
    line-height: ${LINE_HEIGHT.BODY.MEDIUM};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.LARGE};
    line-height: ${LINE_HEIGHT.BODY.LARGE};
  }
`;

const P = styled.p`
  ${PStyles}
`;

export { P, PStyles };
