// @ts-expect-error [🤖 PLEASE FIX 🤖]: Could not find a declaration file for module 'react-gtm-module'. '/Users/aaronpierce/workspace/tac-hsiyc-web/node_modules/react-gtm-module/dist/index.js' implicitly has an 'any' type.
import TagManager from 'react-gtm-module';

/**
 * initGoogleTagManager - Initialise Google Tag Manager with the correct ID
 * @param {string} trackingID
 */
const isDev = process.env.NODE_ENV === 'development';
const { GTM_AUTH_DEV } = process.env;
const { GTM_AUTH_LIVE } = process.env;
const { GTM_PREVIEW_DEV } = process.env;
const { GTM_PREVIEW_LIVE } = process.env;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'TAG_MANAGER_ID' implicitly has an 'any' type.
const initGoogleTagManager = (TAG_MANAGER_ID) => {
  if (TAG_MANAGER_ID) {
    TagManager.initialize({
      gtmId: TAG_MANAGER_ID,
      auth: isDev ? GTM_AUTH_DEV : GTM_AUTH_LIVE,
      preview: isDev ? GTM_PREVIEW_DEV : GTM_PREVIEW_LIVE,
    });
  }
};

/**
 * logTrackingEvent - Log custom tracking event
 * @param {object} eventObject
 */
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'eventObject' implicitly has an 'any' type.
const logTrackingEvent = (eventObject) => {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'dataLayer' does not exist on type 'Window & typeof globalThis'.
  const isAnalyticsInitialized = window.dataLayer;

  if (isAnalyticsInitialized) {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'dataLayer' does not exist on type 'Window & typeof globalThis'.
    window.dataLayer.push(eventObject);
  }
};

/**
 * logPageViewEvent - Log custom pageview event
 * @param {string} current page url
 */
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'url' implicitly has an 'any' type.
const logPageViewEvent = (url) => {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'dataLayer' does not exist on type 'Window & typeof globalThis'.
  const isAnalyticsInitialized = window.dataLayer;

  if (isAnalyticsInitialized) {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'dataLayer' does not exist on type 'Window & typeof globalThis'.
    window.dataLayer.push({
      event: 'pageview', // This event is setup in Tag Manager under "Triggers" and then linked to GA under "tags" in "Universal - Page View - All Pages"
      page: url,
    });
  }
};

export { initGoogleTagManager, logTrackingEvent, logPageViewEvent };
