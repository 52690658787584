/**
 * Used for global styles, all other styles are declared at
 * the component level
 */
import React from 'react';

import SpacingStyles from './spacing-styles';
import TypographyStyles from './typography-styles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'theme' implicitly has an 'any' type.
const GlobalCSS = ({ theme }) => (
  <>
    <SpacingStyles theme={theme} />
    <TypographyStyles />
  </>
);

export default GlobalCSS;
