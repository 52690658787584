import URLS from './urls';

const PAGE_SLUGS = {
  CONTACT_US: 'contact-us',
  CONTENT_PAGES: 'pages',
  HOME: 'home',
  PRIVACY_POLICY: 'privacy-policy',
  RATINGS: 'ratings',
  RESULTS: 'results',
  SAFETY_FEATURES: 'safety-features',
  TERMS: 'terms',
};

export const PAGE_SLUG_URL_MAPPING = {
  [PAGE_SLUGS.CONTACT_US]: URLS.CONTACT_US,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'CONTENT_PAGES' does not exist on type '{ COMPARE: string; CONTACT_US: string; HOME: string; PRIVACY_POLICY: string; RATINGS: string; RESULTS: string; SAFETY_FEATURES_LISTING: string; TERMS_OF_USE: string; SITEMAP: string; FACEBOOK: string; INSTAGRAM: string; TWITTER: string; CHILD_CAR_SEATS: string; }'.
  [PAGE_SLUGS.CONTENT_PAGES]: URLS.CONTENT_PAGES,
  [PAGE_SLUGS.HOME]: URLS.HOME,
  [PAGE_SLUGS.PRIVACY_POLICY]: URLS.PRIVACY_POLICY,
  [PAGE_SLUGS.RATINGS]: URLS.RATINGS,
  [PAGE_SLUGS.RESULTS]: URLS.RESULTS,
  [PAGE_SLUGS.SAFETY_FEATURES]: URLS.SAFETY_FEATURES_LISTING,
  [PAGE_SLUGS.TERMS]: URLS.TERMS_OF_USE,
};

export default PAGE_SLUGS;
