import _ from 'lodash';
import { css } from '@emotion/react';

import BREAKPOINTS from './breakpoints';

const MEDIA_QUERIES = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  // We don't need the array indexes from breakpoints as variables
  // we can check for numbers to remove them
  const labelsAsNumber = parseFloat(label);
  if (_.isNaN(labelsAsNumber)) {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
    acc[label] = (...args) => css`
      @media (min-width: ${
          /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ XS: number; SM: number; MD: number; LG: number; XL: number; }'. */
          BREAKPOINTS[label]
        }) {
        ${css(...args)};
      }
    `;

    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type '`${string}Down`' can't be used to index type '{}'.
    acc[`${label}Down`] = (...args) => css`
      @media (max-width: calc(${
          /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ XS: number; SM: number; MD: number; LG: number; XL: number; }'. */
          BREAKPOINTS[label]
        } - 1px)) {
        ${css(...args)};
      }
    `;
  }
  return acc;
}, {});

export default MEDIA_QUERIES;
