import _ from 'lodash';
import React from 'react';
import { Global, css } from '@emotion/react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'theme' implicitly has an 'any' type.
const createSpacing = (theme) => {
  const { BREAKPOINTS, REM } = theme;
  let rems = ``;

  _.forEach(_.toPairs(REM), ([breakpoint, size], index) => {
    if (index === 0) {
      rems += `
      font-size: ${size};
    `;
    } else {
      rems += `
      @media (min-width: ${BREAKPOINTS[breakpoint]}px) {
        font-size: ${size};
      }
    `;
    }
  });

  return css`
    html {
      ${rems}
    }
  `;
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'theme' implicitly has an 'any' type.
const SpacingStyles = ({ theme }) => {
  return <Global styles={createSpacing(theme)} />;
};

export default SpacingStyles;
