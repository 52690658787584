import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import NextApp from 'next/app';
import React from 'react';
import Router from 'next/router';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';

import GlobalCSS from '../components/global-css';
import GoogleTagManager from '../components/google-tag-manager';
import muiTheme from '../constants/mui-theme';
import styledTheme from '../constants/styled-theme';
import { logPageViewEvent } from '../utils/tracking';

class App extends NextApp {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: 'jssStyles.parentNode' is possibly 'null'.
      jssStyles.parentNode.removeChild(jssStyles);
    }

    this.setupRouteChangeHandler();
  }

  setupRouteChangeHandler = () => {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'url' implicitly has an 'any' type.
    const handleRouteChange = (url) => {
      logPageViewEvent(url);
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  };

  render() {
    const { Component, pageProps } = this.props;

    // Deep merge the themes to avoid overriding nested properties
    const combinedTheme = { ...muiTheme, ...styledTheme };

    return (
      <AppCacheProvider {...this.props}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={combinedTheme}>
            {/* CssBaseline kickstarts an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <GlobalCSS theme={combinedTheme} />
            <GoogleTagManager />
            <Component {...pageProps} {...this.state} />
          </ThemeProvider>
        </StyledEngineProvider>
      </AppCacheProvider>
    );
  }
}

export default App;
